/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'

const loginSchema = Yup.object().shape({
  userName: Yup.string()
    .email("Email không đúng định dạng")
    .max(50, 'Maximum 50 symbols')
    .required('Yêu cầu email'),
  password: Yup.string()
    .max(50, 'Maximum 50 symbols')
    .required('Yêu cầu mật khẩu'),
})



/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

type props = {
  userName?: string,
  handleSuccess?: () => void
}

export const Login: FC<props> = ({ userName, handleSuccess }) => {
  const initialValues = {
    userName: userName || '',
    password: '',
  }

  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: res } = await login(values.userName, values.password)
        if (res.success && res.data) {
          if (res.data.isSuccess) {
            saveAuth(res.data)
            const { data: user } = await getUserByToken()
            setCurrentUser(user.data)
            handleSuccess?.()
          } else {
            saveAuth(undefined)
            setStatus('Sai tên đăng nhập hoặc mật khẩu')
          }

        }

      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Tài khoản không tồn tại trong hệ thống')

      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11' hidden={!!userName}>
        <h1 className='text-dark fw-bolder mb-3'>Đăng nhập</h1>
      </div>
      {/* begin::Heading */}

      {formik.status &&
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      }

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          readOnly={!!userName}
          placeholder='Email'
          {...formik.getFieldProps('userName')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.userName && formik.errors.userName },
            {
              'is-valid': formik.touched.userName && !formik.errors.userName,
            }
          )}
          type='text'
          name='userName'
          autoComplete='off'
        />
        {formik.touched.userName && formik.errors.userName && (
          <div className='text-danger'>
            <span role='alert'>{formik.errors.userName}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mật khẩu</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}



      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Đăng nhập</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Đang đăng nhập...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
      <div className='border-top border-secondary pt-5 text-center text-muted mx-5' hidden={!!userName}>
        Bạn không có tài khoản?
        <Link className='ms-2' to={"/auth/registration"} >Đăng ký tài khoản</Link>

      </div>


    </form>
  )
}
