import { FC, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';

import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { DisableSidebar } from '../../_metronic/layout/core';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { useAuth } from '../modules/auth';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';


const PrivateRoutes = () => {
  const TestsPage = lazy(() => import('../modules/tests/TestsPage'));
  const ExamsPage = lazy(() => import('../modules/exams/ExamsPage'));
  const CollectionsPage = lazy(() => import('../modules/collections/CollectionsPage'));
  const ClassRoomsPage = lazy(() => import('../modules/class-rooms/ClassRoomsPage'));
  const { currentUser } = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        
        <Route path='collections/*' element={
          <SuspensedView>
            <CollectionsPage />
          </SuspensedView>
        } />

        
        <Route path='exams/*' element={
          <SuspensedView>
            <ExamsPage />
          </SuspensedView>
        } />

        <Route
          path='tests/*'
          element={
            <SuspensedView>
              <TestsPage />
            </SuspensedView>
          }
        />

        <Route
          path='class-rooms/*'
          element={
            <SuspensedView>
              <ClassRoomsPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export { PrivateRoutes }
