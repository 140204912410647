import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useAuth } from '../../modules/auth';
import { Login } from '../../modules/auth/components/Login';

type props = {
    handleClose: () => void,
    show: boolean
}

const RequestLoginModal: FC<props> = ({
    show,
    handleClose
}) => {

    const { currentUser } = useAuth()


    return (
        <Modal show={show} onHide={handleClose} size="lg" keyboard={false} backdrop={"static"}>
            <Modal.Header>
                <Modal.Title>Bạn cần đăng nhập lại để làm bài thi</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Login userName={currentUser?.email} handleSuccess={handleClose}/>
            </Modal.Body>
        </Modal>
    )
}

export default RequestLoginModal
