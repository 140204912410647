/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl';

import { AsideMenuItem } from './AsideMenuItem';

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title="Lịch học"
        mIcon='dashboard'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Học liệu</span>
        </div>
      </div>

      <AsideMenuItem
        to='/class-rooms'
        title="Lớp học"
        mIcon='school'
      />

      <AsideMenuItem
        to='/collections'
        title='Bộ đề luyện tập'
        mIcon='quiz'
      >

      </AsideMenuItem>

      <AsideMenuItem
        to='/tests'
        title='Bài thi'
        mIcon='clinical_notes'
      >

      </AsideMenuItem>
    </>
  )
}
