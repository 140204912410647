import axios from 'axios'
import {AuthModel, UpdateUserInfo, UserModel} from './_models'
import { BaseResponse } from '../../../common/models/_models'

const API_URL = process.env.REACT_APP_API_URL

export const AUTH_URL = `${API_URL}/api/auth`
export const REFRESH_TOKEN = `${API_URL}/api/auth/refresh-token/`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(userName: string, password: string) {
  return axios.post<BaseResponse<AuthModel>>(`${AUTH_URL}/login`, {
    userName,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  phoneNumber: string
) {
  return axios.post<BaseResponse<AuthModel>>(`${AUTH_URL}/signup`, {
    email,
    firstName: firstname,
    lastName: lastname,
    password,
    phoneNumber,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken() {
  return axios.get<BaseResponse<UserModel>>(`${AUTH_URL}/verify-token`)
}

export function updateUserInfo(request: UpdateUserInfo) {
  return axios.put<BaseResponse<boolean>>(`${AUTH_URL}/update`, request)
}

export function refreshToken(refreshToken: string) {
  return axios.post<BaseResponse<AuthModel>>(REFRESH_TOKEN + refreshToken)
}