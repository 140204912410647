import { EnableSidebar, PageTitle } from '../../../_metronic/layout/core'
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget9,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15,
} from '../../../_metronic/partials/widgets'
import { useAuth } from '../../modules/auth'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ScheduleModel, ScheduleSearchModel } from '../../common/models/_models';
import clsx from 'clsx';
import { getStudentSchedules } from './core/_requests';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface EventModel {
  id: string,
  name: string,
  allDay: boolean,
  start: Date,
  end: Date
  scheduleType: string
}

const DashboardPage = () => {
  const now = new Date();
  const auth = useAuth();
  const [events, setEvents] = useState<EventModel[]>([])
  const [event, setEvent] = useState<EventModel>()
  const [view, setView] = useState('week')
  const [currentDay, setCurrentDay] = useState(new Date())
  const localizer = momentLocalizer(moment);

  const handleChangeDate = (newDate: Date, view: string) => {
    setView(view);
    setCurrentDay(newDate)
    let request = {
      startDate: newDate,
      endDate: newDate
    }

    if (view === 'week') {
      request = {
        startDate: moment(newDate).startOf('week').toDate(),
        endDate: moment(newDate).endOf('week').toDate()
      }
    } else 
    {
      request = {
        startDate: moment(newDate).startOf('day').toDate(),
        endDate: moment(newDate).endOf('day').toDate()
      }
    }


    getSchedule(request);

    // let weekDates = [];

    // for (let i = 0; i < 7; i++)
    //   weekDates.push(moment(newDate).add(i, 'd'));


    // console.log(weekDates);
  }

  const getSchedule = async (request: ScheduleSearchModel) => {
    if (auth.currentUser) {
      let { data } = await getStudentSchedules(request);
      if (data.data) {
        let eventData = data.data.map(x => {
          return {
            start: new Date(x.startDate),
            end: new Date(x.endDate),
            name: x.name,
            id: x.parentId,
            allDay: false,
            scheduleType: x.scheduleType
          } as EventModel
        });
        setEvents(eventData)
      }
    }
  }


  useEffect(() => {
    getSchedule({
      startDate: moment().startOf('week').toDate(),
      endDate: moment().endOf('week').toDate()
    })
  }, [])


  const Event = (e: EventModel, viewType: string) => {
    return (
      <OverlayTrigger trigger="click" placement="top" overlay={<Popover id="popover-basic">
        <Popover.Header as="h3">{e?.name}</Popover.Header>
        <Popover.Body>
          <div> {moment(e.start).format('HH:mm')} - {moment(e.end).format('HH:mm')}</div>
        </Popover.Body>
      </Popover>} rootClose>

        <div className={clsx('w-100 h-100 border overflow-auto d-flex small text-dark', (e.scheduleType === 'Test' ? 'bg-light-warning border-warning ' : 'bg-light-primary border-primary '))}>

          {
            viewType === 'day' && e.scheduleType === 'Test' && <div>
              <span className="material-symbols-rounded fs-5">
                edit_square
              </span>
            </div>

          }
          {
            viewType === 'day' && e.scheduleType === 'ClassRoom' && <div>
              <span className="material-symbols-rounded fs-5">
                school
              </span>
            </div>

          }

          <div className='m-1'>
            <div> {e.name}</div>
            <div className='mt-1'> {moment(e.start).format('HH:mm')} - {moment(e.end).format('HH:mm')}</div>
          </div>

        </div>
      </OverlayTrigger>
    )
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <Calendar
          events={events}
          defaultDate={new Date()}
          localizer={localizer}
          views={{ day: true, week: true }}
          defaultView='week'
          onNavigate={(newDate, view) => handleChangeDate(newDate, view)}
          components={{
            event: (e) => Event(e.event, view)
          }}
          startAccessor={'start'}
          endAccessor={'end'}
          min={new Date(0, 0, 0, 7, 0, 0)}
          max={new Date(0, 0, 0, 22, 0, 0)}
          onView={(view) => handleChangeDate(currentDay, view)}
          messages={{
            next: ">",
            previous: "<",
            today: "Hôm nay",
            month: "Tháng",
            week: "Tuần",
            day: "Ngày"
          }}
        />
      </div>
    </div>
  )
}

const DashboardWrapper = () => {
  const { currentUser } = useAuth()

  return (
    <>
      <PageTitle description='Welcome back' breadcrumbs={[]}>
        {' Hello, ' + currentUser?.fullName}
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
