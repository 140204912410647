import { toast } from 'react-toastify'
import { AuthModel } from './_models'
import { refreshToken } from './_requests';
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(function (response: any) {
    if (response.data?.messages) {
      toast.error(response.data.messages);
    }
    return response;
  }, async function (error: any) {

    if (error.request.status === 401) {
      console.log("Invalid token" + error.request.responseURL);

      var keyValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
      if (keyValue) {
        let authModel = JSON.parse(keyValue);

        removeAuth();
        console.log("\tRemove current token");

        let { data } = await refreshToken(authModel.refreshToken);
        console.log("\t Refresh token ");
        if (data.data?.isSuccess) {
          console.log("\t Set new token ");
          setAuth(data.data);

          console.log("\t Retry request ");
          return axios.request(error.config);
        }
      }
      else {
        console.log("\t Retry request ");
        await new Promise(resolve => setTimeout(resolve, 3000));
        return axios.request(error.config);
      }

      return;
    }

    if (error.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Có lỗi trong quá trình xử lý.");
    }
    
    return { data: { data: null } };
  });
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
