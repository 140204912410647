/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';

import { useAuth } from '../../../../app/modules/auth';
import { toAbsoluteUrl } from '../../../helpers';
import UpdateUserModelModal from '../../../../app/common/modals/UpdateUserModelModal';
import { useState } from 'react';

const UserMenu = () => {
  const { currentUser, logout } = useAuth()
  const [show, setShow] = useState(false)
  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {currentUser?.fullName}
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {currentUser?.email}
              </a>
            </div>
          </div>
        </div>
        <div className='separator my-2'></div>
        <div className='menu-item px-5'>
          <a href='#' onClick={() => setShow(true)} className='menu-link px-5'>
            Thông tin cá nhân
          </a>
        </div>
        <div className='separator my-2'> </div>
        <div className='menu-item px-5'>
          <a onClick={logout} className='menu-link px-5'>
            Đăng xuất
          </a>
        </div>
      </div>

      <UpdateUserModelModal show={show} handleClose={() => setShow(false)} handleSuccess={() => setShow(false)}/>
    </>
  )
}

export { UserMenu }
