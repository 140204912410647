import clsx from 'clsx';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { PasswordMeterComponent } from '../../../_metronic/assets/ts/components';
import { useAuth } from '../../modules/auth';
import { getUserByToken, updateUserInfo } from '../../modules/auth/core/_requests';

type props = {
    handleClose: () => void,
    show: boolean,
    handleSuccess: () => void
}


const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
        .required('Không được để trống'),
    phoneNumber: Yup.string()
        .required('Không được để trống'),
    email: Yup.string()
        .email('Sai định dạng email')
        .min(3, 'Tối thiểu 3 ký tự')
        .max(50, 'Tối đa 50 ký tự')
        .required('Không được để trống'),
    lastname: Yup.string()
        .required('Không được để trống'),
    password: Yup.string()
        .min(6, 'Tối thiểu 6 ký tự')
        .max(50, 'Tối đa 50 ký tự'),
    changepassword: Yup.string()
        .min(6, 'Tối thiểu 6 ký tự')
        .max(50, 'Tối đa 50 ký tự')
})


const UpdateUserModelModal: FC<props> = ({
    show,
    handleClose,
    handleSuccess
}) => {

    const { currentUser, setCurrentUser } = useAuth()


    const [loading, setLoading] = useState(false)

    const [initialValues, setInitialValues] = useState({
        firstname: currentUser?.firstName || '',
        lastname: currentUser?.lastName || '',
        email: currentUser?.email || '',
        password: '',
        changepassword: '',
        phoneNumber: currentUser?.phoneNumber || '',

    })

    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true)

            let { data } = await updateUserInfo(
                {
                    firstName: values.firstname,
                    lastName: values.lastname,
                    oldPassword: values.password,
                    password: values.changepassword,
                    phoneNumber: values.phoneNumber
                }
            )
            if (data.data) {
                const { data: user } = await getUserByToken()
                if (user.data) {
                    setCurrentUser(user.data);
                    handleSuccess();
                }
            }

            setSubmitting(false)
            setLoading(false)
        },
    })

    useEffect(() => {
        formik.resetForm();

        setInitialValues({
            firstname: currentUser?.firstName || '',
            lastname: currentUser?.lastName || '',
            email: currentUser?.email || '',
            password: '',
            changepassword: '',
            phoneNumber: currentUser?.phoneNumber || '',

        });


        PasswordMeterComponent.bootstrap()
    }, [])


    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header>
                <Modal.Title>Thông tin cá nhân</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_signup_form'
                    onSubmit={formik.handleSubmit}
                >

                    {formik.status && (
                        <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{formik.status}</div>
                        </div>
                    )}

                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className='fv-row mb-8'>
                                <label className='form-label fw-bolder text-dark fs-6'>Họ</label>
                                <input
                                    type='text'
                                    autoComplete='off'
                                    {...formik.getFieldProps('firstname')}
                                    className={clsx(
                                        'form-control bg-transparent',
                                        {
                                            'is-invalid': formik.touched.firstname && formik.errors.firstname,
                                        },
                                        {
                                            'is-valid': formik.touched.firstname && !formik.errors.firstname,
                                        }
                                    )}
                                />
                                {formik.touched.firstname && formik.errors.firstname && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.firstname}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>

                        <div className='col-sm-6'>
                            <div className='fv-row mb-8'>
                                <label className='form-label fw-bolder text-dark fs-6'>Tên</label>
                                <input
                                    type='text'
                                    autoComplete='off'
                                    {...formik.getFieldProps('lastname')}
                                    className={clsx(
                                        'form-control bg-transparent',
                                        {
                                            'is-invalid': formik.touched.lastname && formik.errors.lastname,
                                        },
                                        {
                                            'is-valid': formik.touched.lastname && !formik.errors.lastname,
                                        }
                                    )}
                                />
                                {formik.touched.lastname && formik.errors.lastname && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.lastname}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='col-sm-6'>
                            <div className='fv-row mb-8'>
                                <label className='form-label fw-bolder text-dark fs-6'>Số điện thoại</label>
                                <input
                                    type='text'
                                    autoComplete='off'
                                    {...formik.getFieldProps('phoneNumber')}
                                    className={clsx(
                                        'form-control bg-transparent',
                                        {
                                            'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber,
                                        },
                                        {
                                            'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
                                        }
                                    )}
                                />
                                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.phoneNumber}</span>
                                        </div>
                                    </div>
                                )}
                            </div>


                        </div>
                        <div className='col-sm-6'>
                            <div className='fv-row mb-8'>
                                <label className='form-label fw-bolder text-dark fs-6'>Email</label>
                                <input
                                    type='email'
                                    readOnly
                                    autoComplete='off'
                                    {...formik.getFieldProps('email')}
                                    className={clsx(
                                        'form-control bg-secondary',
                                        { 'is-invalid': formik.touched.email && formik.errors.email },
                                        {
                                            'is-valid': formik.touched.email && !formik.errors.email,
                                        }
                                    )}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.email}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>







                    <div className='fv-row mb-8' data-kt-password-meter='true'>
                        <div className='mb-1'>
                            <label className='form-label fw-bolder text-dark fs-6'>Mật khẩu</label>
                            <div className='position-relative mb-3'>
                                <input
                                    type='password'
                                    placeholder='Password'
                                    autoComplete='off'
                                    {...formik.getFieldProps('password')}
                                    className={clsx(
                                        'form-control bg-transparent',
                                        {
                                            'is-invalid': formik.touched.password && formik.errors.password,
                                        },
                                        {
                                            'is-valid': formik.touched.password && !formik.errors.password,
                                        }
                                    )}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.password}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                className='d-flex align-items-center mb-3'
                                data-kt-password-meter-control='highlight'
                            >
                                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                            </div>
                        </div>
                        <div className='text-muted'>
                            Trên 6 ký tự bao gồm số và ký tự đặc biệt.
                        </div>
                    </div>

                    <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Nhập lại mật khẩu</label>
                        <input
                            type='password'
                            autoComplete='off'
                            {...formik.getFieldProps('changepassword')}
                            className={clsx(
                                'form-control bg-transparent',
                                {
                                    'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                                },
                                {
                                    'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                                }
                            )}
                        />
                        {formik.touched.changepassword && formik.errors.changepassword && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.changepassword}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" type="button" onClick={handleClose} >
                    Đóng
                </Button>
                <button
                    type='submit'
                    className='btn btn-lg btn-primary'
                    onClick={formik.submitForm}
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Lưu</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Vui lòng đợi...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </Modal.Footer>

        </Modal>
    )
}

export default UpdateUserModelModal
