import axios from 'axios'
import { BaseListResponse, BaseResponse, ScheduleModel, ScheduleSearchModel, SearchModel, UserModel } from '../../../common/models/_models'
import {  PracticeCollectionListModel, StudentPracticeVolumeModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const SCHEDULES_API = `${API_URL}/api/schedules`

export function getStudentSchedules(request: ScheduleSearchModel) {
  return axios.post<BaseResponse<ScheduleModel[]>>(`${SCHEDULES_API}`, request)
}
